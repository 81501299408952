<template>
  <div class="row">
    <div class="col-12">
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2  mb-4">
          <div
            class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3"> 
            <h6 class="col text-white text-capitalize ps-3">Participants</h6>
          </div>    
          
          <div
            v-if="this.showAlert"
            :class="[
              'alert',
              this.responseStatusCode == 'success'
                ? 'alert-success'
                : this.responseStatusCode == 'error'
                ? 'alert-danger'
                : this.responseStatusCode == 'pending'
                ? 'alert-warning'
                : 'alert-info',
            ]"
            role="alert"
          >
            <span class="text-alert">{{ this.alertMessage }}</span>
          </div>

          <Loading message="Loading datas" :loading = "loading"/>

          <div v-if="loading == false && allEnrollments != null" class="col-lg-12 position-relative z-index-2 mt-5">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                  <mini-statistics-card
                    :title="{ text: 'Students', value: this.data_api_enrollments_list.total_enrollments_active + this.data_api_enrollments_list.total_enrollments_completede + this.data_api_enrollments_list.total_enrollments_inactive }"
                    detail=""
                    :icon="{
                      name: 'check',
                      name: 'check',
                      color: 'text-white',
                      background: 'info',
                    }"
                  />
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                  <mini-statistics-card
                    :title="{ text: 'Active', value: this.data_api_enrollments_list.total_enrollments_active }"
                    detail=""
                    :icon="{
                      name: 'leaderboard',
                      color: 'text-white',
                      background: 'info',
                    }"
                  />
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                  <mini-statistics-card
                    :title="{ text: 'Completed', value: this.data_api_enrollments_list.total_enrollments_completede }"
                    detail=""
                    :icon="{
                      name: 'book',
                      color: 'text-white',
                      background: 'success',
                    }"
                  />
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                  <mini-statistics-card
                    :title="{ text: 'Pre-registered', value: this.data_api_enrollments_list.total_enrollments }"
                    detail=""
                    :icon="{
                      name: 'info',
                      color: 'text-white',
                      background: 'danger',
                    }"
                  />
                </div>
              </div>
          </div>
          
          <div v-if="loading == false" class="pb-2 pt-0 mt-0 d-flex justify-content-start gap-4 mb-4">
            <div class="col-6">
              <label class="mt-3">Project</label>
              <select  class="form-select form-select-lg" v-model="project" @change="selectedProject">
                <option v-if="projects == null" value="">Charger ... </option>
                <option v-for="project in projects" :value="project.course_id" :key="project.course_id">{{ project.project_name }} -- {{ project.course_name }}</option>
              </select>
            </div>

            <div v-if="viewFilterField == true" class="p-0 d-flex w-100">
              <div class="w-100">
                <label class="mt-3">Filter</label>
                <div class="d-flex align-items-center gap-2">
                  <input type="text" class="form-control form-control-lg custom-input" v-model="searchFilter" placeholder="Search by Name, Phone or UTM">

                  <div class="d-flex algin-items-end">                      
                    <button @click="getAllenrollments()" class="btn bg-gradient-info my-0">FILTER</button>
                  </div>
                </div>
              </div>
            </div>              
          </div>

          <!-- select list for labels -->
          <div v-if="project != null && !view_secndary_info && !showPushBathDetails" class="p-0 d-flex w-100">
            <div v-if="labels != null && labels!='' ">
              <label class=" fs-6 mb-0" >Landing page filters</label>                
              <div class="col-12 d-flex flex-wrap">
                <div v-for="label in labels" :key="label.fields.id" class="col-4 px-2">
                  <label v-if="label.fields.label.length > 46" class="mt-3" :title="label.fields.label">{{ label.fields.label.substring(0,45) }}...</label>
                  <label v-else class="mt-3" :title="label.fields.label">{{ label.fields.label }}</label>
                    <select 
                      class="form-select form-select-lg" 
                      v-model="selectedFields[label.fields.id]" 
                      @change="updateSelectedFields(label.fields.id)">
                      <option value="">Change...</option>
                      <option v-for="field in label.data" :value="field.response_data" :key="field.id">
                        {{ field.response_data }}
                      </option>
                    </select>
                </div>
              </div>
            </div>
          </div>          
          
          <div v-if="allEnrollments!=null && loading == false" class="card px-0 pb-2">
            <div v-if="project != null" class="w-100 d-flex justify-content-between align-items-center my-0 px-1">
              <div>
                <h5>Selected: {{ this.data_course.external_exercise_id }} -- {{ this.data_course.name }}</h5>
              </div>
              
              <div v-if="project != null" class="d-flex justify-content-end align-items-center gap-2">
                <router-link
                  class="btn col-md-12 bg-gradient-info p-1 fs-5"
                  :to="{ name: 'Registration Page', params: { id: project } }"
                >
                  <i class="bi bi-person-plus"></i>
                </router-link>
                <router-link
                  class="btn col-md-12 bg-gradient-info p-1 fs-5"
                  :to="{ name: 'List Push Batch By User', params: { id: project }}"
                >
                  <i class="bi bi-person-up"></i>
                </router-link>
              </div>
            </div>

            <div v-if="showAlertStatus" :class="['alert', responseStatusCode != 200 ? 'alert-danger': 'alert-success', 'alert-dismissible', 'fade', 'show']" role="alert">
              <span class="text-alert">{{ alertMessage }}</span>
              <button type="button" class="btn-close d-flex align-items-center px-4" data-bs-dismiss="alert" aria-label="Close">
                <i class="bi bi-x fs-3"></i>
              </button>
            </div> 

            <div class="table-responsive p-0">                
              <table class="table table-striped table-hover align-items-center mb-4">
                <thead class="bg-dark">
                  <tr>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                      Number Inscription                        
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                      Participant Status
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                      Participant Name                        
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                      UTM                      
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                      Inscription                        
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                      Modified                        
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                      Deadline change status
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                      Options                        
                    </th>                      
                  </tr>
                </thead>
                
                <tbody>
                  <tr v-for="enrollment in allEnrollments" :key="enrollment.id">
                    <td class="align-middle text-center text-sm">
                      <div class="d-flex px-2 py-1 justify-content-center align-items-center">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                          <h6 class="mb-0 text-sm">{{ enrollment.id }}</h6>
                        </div>
                      </div>
                    </td>

                    <td class="align-middle text-center text-sm">
                      <span v-if="enrollment.status == 'active'" class="badge badge-sm bg-gradient-success col-md-7"
                        >{{ enrollment.status }}</span
                      >
                      <span v-if="enrollment.status == 'completed'" class="badge badge-sm bg-gradient-success col-md-7"
                        >{{ enrollment.status }}</span
                      >
                      <span v-if="enrollment.status == 'initial'" class="badge badge-sm bg-gradient-info col-md-7"
                        >{{ enrollment.status }}</span
                      >
                      <span v-if="enrollment.status == 'missing_token'" class="badge badge-sm bg-gradient-info col-md-7"
                        >{{ enrollment.status }}</span
                      >
                      <span v-if="enrollment.status == 'inactive'" class="badge badge-sm bg-gradient-danger col-md-7"
                        >{{ enrollment.status }}</span
                      >
                    </td>                     

                    <td class="align-middle text-center text-sm">
                      <div class="d-flex px-2 py-1 justify-content-center align-items-center">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                          <h6 class="mb-0 text-sm">{{ enrollment.phone_student }}</h6>
                          <p class="text-xs text-secondary mb-0">
                            {{ enrollment.name_student }}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{ enrollment.utm }}</span
                      >
                    </td>

                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{ formatDate(enrollment.created) }}</span
                      >
                    </td>

                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{ formatDate(enrollment.modified) }}</span
                      >
                    </td>

                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ enrollment.deadlineChangeStatus ? formatDate(enrollment.deadlineChangeStatus) : '' }}
                      </span>
                    </td>

                    <td class="align-middle text-center">
                      <div class="m-1">
                        <router-link 
                          v-bind:title="buttonTitle.info"
                          class="btn bg-gradient-warning my-0 fs-6 m-1"
                          :to="{ name: 'Batch Participants Enrollments', params: { id: enrollment.id, course_id: this.projectId } }"
                        >
                          <i class="bi bi-person-lines-fill"></i>
                        </router-link>
                      
                        <button v-bind:title="buttonTitle.changeStatus" v-if="enrollment.status == 'active'" class="btn bg-gradient-success my-0 m-1" data-bs-toggle="modal" data-bs-target="#modalChangeEnroll" @click="newStatus(enrollment.id,'inactive', enrollment.status, enrollment.deadlineChangeStatus)"><i class="bi bi-toggle2-on fs-6"></i></button>
                        
                        <button v-bind:title="buttonTitle.changeStatus" v-else-if="enrollment.status == 'inactive'" class="btn bg-gradient-danger my-0 m-1" data-bs-toggle="modal" data-bs-target="#modalChangeEnroll" @click="newStatus(enrollment.id,'active', enrollment.status, enrollment.deadlineChangeStatus)"><i class="bi bi-toggle2-off  fs-6"></i></button>
                        
                        <button v-bind:title="buttonTitle.changeStatus" v-else class="btn bg-gradient-secondary my-0 m-1" disabled ><i class="bi bi-x-circle fs-6 "></i></button>

                        <button v-bind:title="buttonTitle.block" v-if="enrollment.student_blocked == false" data-bs-toggle="modal" data-bs-target="#modalBlockStudent" @click="_block(enrollment.id, false)" class="btn bg-gradient-success my-0 m-1 fs-6"><i class="bi bi-unlock fs-6"></i></button>
                        
                        <button v-bind:title="buttonTitle.unlock" v-else data-bs-toggle="modal" data-bs-target="#modalBlockStudent" @click="_block(enrollment.id, true)" class="btn bg-gradient-danger my-0 m-1 fs-6"><i class="bi bi-lock"></i></button>
                      </div>
                    </td>                    
                  </tr>                  
                </tbody>
              </table>

              <nav v-if="this.total_pages > 1" aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li v-if="this.page_data_enrollment <= this.total_pages && this.page_data_enrollment > 1" class="page-item">
                    <a @click="decrementPage" class="page-link"><i class="bi bi-chevron-double-left"></i></a>
                  </li>
                  <li class="page-item px-2"><a class="page-link">{{ this.page_data_enrollment }}</a></li>
                  <li v-if="this.total_pages > 1" class="page-item d-flex align-items-center">of</li>
                  <li v-if="this.total_pages > 1" class="page-item px-2" style="margin-left: 0.2%;"><a class="page-link">{{ this.total_pages }}</a></li>
                  <li v-if="this.page_data_enrollment < this.total_pages" class="page-item">
                    <a @click="incrementPage" class="page-link"><i class="bi bi-chevron-double-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal change enroll -->
  <div class="modal" id="modalChangeEnroll" tabindex="-1" aria-labelledby="modalChangeEnrollLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalChangeEnrollLabel">Change Enrollment</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h7>Change the status of the participant?</h7>                   
            <div v-if="this.old_status == 'active'" class="mt-2">  


              <label for="subscriptions_deadline" class="form-label">Do you want a specific date to change the status to deactivated?        <br> (UTC-3)</label>                    
              <div class="input-group input-group-outline">                      
                <input id="subscriptions_deadline" type="date" placeholder="dd/mm/yyyy" class="form-control form-control-default" v-model="this.deadline" name="subscriptions_deadline">
              </div>
              <div class="input-group input-group-outline">

                <small class="text-danger" >Actual deadline: {{ this.old_deadline }}</small>                     
                <small v-if=" this.deadline == null && this.old_deadline != 'No deadline'" for="subscriptions_deadline" class="text-danger">If you save without a date, it will change the status now.</small>                        
              </div>
              
            </div>
            <div v-if=" this.old_status == 'inactive'" class="mt-2">
              <label for="subscriptions_deadline" class="form-label">Do you want a specific date to change the status to activated?        <br> (UTC-3)</label>                      
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn bg-gradient-danger" data-bs-dismiss="modal" @click="clearStatus()">Close</button>
          <button type="button" class="btn bg-gradient-success" @click="changeStatus()">Save changes</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal block student -->
  <div class="modal fade" id="modalBlockStudent" tabindex="-1" aria-labelledby="modalBlockStudentLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalBlockStudentLabel">Block Student</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div v-if=" this.statusBlock == false" class="modal-body text-center" >
          <div class="input-group input-group-outline">
            <h6>Do you want to block the student?</h6>
            <small class="text-danger" >Blocked students will lose all access to the courses on offer.</small> 
          </div>
        </div>
        <div v-else class="modal-body text-center" >
          <div v-if=" this.statusBlock == true" class="input-group input-group-outline">                  
            <h6> Do you want to unblock the student?</h6>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn bg-gradient-danger" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn bg-gradient-success" @click="blockStudent()">Block</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {http, http_file, url_from_app_user} from "../http/index";
import Loading from "@/views/components/Loading.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";

export default {
  name: "ControUsers",

  components : {
    MaterialButton,
    MiniStatisticsCard,
    Loading    
  },

  computed : {    
    camposPreenchidos() {
      if(this.project !=  false && this.course != false){
        return true;
      }
    }
  },

  data() {
    return {
      course: '',
      loading: true,
      projects : null,
      projectId : null,
      showAlert: false,
      alertMessage: "",
      searchFilter: "",
      total_pages: null,
      data_course: null,
      selectedProj: true,
      allEnrollments: null,
      showAlertStatus: false,
      viewFilterField: false,
      responseStatusCode: "",
      page_data_enrollment: 1,
      token_user: localStorage.getItem("token"),
      
      deadline:null,
      old_status: null,
      old_deadline: null,
      
      buttonTitle:{
        info: "Info student",
        block: "Block student?",
        unlock: "Unblocking student?",
        changeStatus: "Change status"
      },
      
      statusBlock: null,
      enrollment_id: null,
      
      view_secndary_info: false,
      lp_url_from_course: null,
      showLP: false,
      data_api_enrollments_list: null,
      data_secundary_info: {
        course_info: null,
        project_info: null,
        status_enroll: null,
        sender_enroll: null, 
        inscription_data: null, 
        info_LP: null,
        info_bot: null,
        depositions: null
      },

      pushBatchIdentifier: "",
      pushBatchFile: "",
      showPushBatchForm: false,
      pushBatches : null,
      showImportContainer : false,

      showPushBathDetails: false,
      pushLines: null,

      isImporting: false,


      
      labels:null,
      selectedFields:{},
      list_select_field: [],
      checked:false,
    };
  },

  mounted() {
    this.getAllProjects()

    if (this.$route.params.id) {
        this.projectId = this.$route.params.id      
        this.getAllenrollments()        
      }
  },  

  methods: {
    formatDate(date, time = true) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      
      if (this.allEnrollments) {
        this.number_enroll = this.allEnrollments.length;
      } else {
        this.number_enroll = 0;
      }
      
      if (!time) {
        return `${year}\-${month}\-${day}`;
      }else{
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }
      
    },

    incrementPage() {
        this.page_data_enrollment++;
        this.getAllenrollments();
    },

    decrementPage() {
      if (this.page_data_enrollment > 1) {
          this.page_data_enrollment--;
          this.getAllenrollments();
      }
    },

    getAllProjects(){
      let payload = {
        token: this.token_user
      }
      
      http.post('/api/v1/dashboard/list-projects-by-client/', payload)
      .then((response) => (this.projects = response.data.projects))
      .finally(() => {
        this.loading = false;
      });
    },

    selectedProject(){
      this.total_pages = null
      this.page_data_enrollment = 1
      
      if(this.project != ''){
        this.projectId = this.project;
      } else{
        this.selectedProj = true;
        this.course = '';
      }
      
      this.getAllLabels()
      this.getAllenrollments()      
    },

    getAllenrollments(){
      this.loading = true

      if (this.projectId !== null) {
        this.project = this.projectId          
      }

      let payload = {
        token_user: this.token_user,
        course_id: this.project,
        pagination: this.page_data_enrollment,
        filter: this.searchFilter,
        fields_filter: this.list_select_field
      }

      http.post('/api/v1/dashboard/list-enrollment-from-course-client/', payload)
      .then((response) => (this.data_api_enrollments_list = response.data))          
      .finally(() => {
        this.loading = false;
        this.viewFilterField = true
        this.total_pages = this.data_api_enrollments_list.total_pages
        this.allEnrollments = this.data_api_enrollments_list.enrollments
        this.data_course = this.data_api_enrollments_list.information_course
      });        
    },

    newStatus(enrollment_id, new_status, old_status, deadlineChangeStatus){
      this.enrollment_id = enrollment_id
      this.new_status = new_status
      this.old_status = old_status
      this.old_deadline = deadlineChangeStatus ? this.formatDate(deadlineChangeStatus) : "No deadline"
    },

    clearStatus(){
      this.enrollment_id = null
      this.new_status = null
      this.deadline = null
    },

    async changeStatus(){
      let payload = {
        token_user: this.token_user,
        enrollment_id: this.enrollment_id,
        status: this.new_status,
        deadline: this.deadline ? this.deadline : undefined
      }

      http.post('/api/v1/dashboard/change-status-enrollment/', payload)
      .then((response) => {
        this.showAlertStatus = true
        this.responseStatusCode = response.status
        this.alertMessage = "Enrollment status changed successfully."
      })
      .catch((error) => {
        this.showAlertStatus = true
        this.alertMessage = "Error changing the status of the participant."
        this.responseStatusCode = error.status
      })
      .finally(() => {
        this.enrollment_id = null
        this.new_status = null
        $('#modalChangeEnroll').modal('hide')
        this.loading = true
        this.deadline = null
        setTimeout(() => {
          this.showAlertStatus = false
          this.getAllenrollments()
        }, 3500)
      })
    },

    blockStudent(){
      let payload = {
        token_user: this.token_user,
        enrollment_id: this.enrollment_id,
        blocked_status: this.statusBlock
      }

      http.post('/api/v1/dashboard/block_student/', payload)
      .then((response) => {
        this.showAlertStatus = true
        this.responseStatusCode = response.status
        this.alertMessage = "Student blocked successfully."
      })
      .catch((error) => {
        this.showAlertStatus = true
        this.showAlertStatus = true;
        if (error.response && error.response.status === 422) {
          this.alertMessage = "This student has been blocked for violating the platform's guidelines, please contact support.";
        } else {
          this.alertMessage = "Error on blocking the student.";
          this.responseStatusCode = error.response ? error.response.status : 'unknown';
        }
      })
      .finally(() => {
        this.enrollment_id = null
        this.statusBlock = null
        $('#modalBlockStudent').modal('hide')
        this.loading = true

        setTimeout(() => {
          this.showAlertStatus = false
          this.getAllenrollments()
        }, 3500)
      })
    },

    _block(enrollment_id, status){
      this.enrollment_id = enrollment_id
      this.statusBlock = status
    },

    getAllLabels(){
      let payload = {
        token_user: this.token_user,
        course_id: this.project
      }

      http.post('/api/v1/dashboard/list_labels_by_course/', payload)
      .then((response) => {        
        this.labels = response.data
      })
      .catch((error) => {
      })
      .finally(() => {        
      })
    },

    updateSelectedFields(labelId) {
      let selectedId = this.selectedFields[labelId];
      
      if (selectedId === "") {
        delete this.selectedFields[labelId];
      } else {
        this.selectedFields[labelId] = selectedId;
      }
      
      this.list_select_field = Object.entries(this.selectedFields).map(([key, value]) => ({ [key]: value }));
      this.getAllenrollments()
    },    
  }
}
</script>

<style scoped>
  .text-alert{
    color: #FFF;
  }

  .form-select {
    background-position: right 18px center
  }
  
  .form-select:focus-visible{
    font-weight: bold;
    border: 1px solid #d2d6da
  }

  .custom-input {
      border: 1px solid #ced4da;
      border-radius: 5px;
      padding-left: 10px;
  }

  .custom-input:focus,
  .custom-input:active {
      border-color: #6c757d;
  } 
</style>